import { render, staticRenderFns } from "./sparkline.vue?vue&type=template&id=9e180498&scoped=true&"
import script from "./sparkline.vue?vue&type=script&lang=ts&"
export * from "./sparkline.vue?vue&type=script&lang=ts&"
import style0 from "./sparkline.vue?vue&type=style&index=0&id=9e180498&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e180498",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
installComponents(component, {VIcon,VSparkline})
